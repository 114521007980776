#QModalNewsletter {
  align-self: flex-end;
}

.QModalNewsletter {
  width: 100%;
  background: #FFFFFF;
}

.QModalNewsletterContainer {
  position: relative;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 25px 16px;
}

.QModalNewsletterContainer form {
  margin-top: 16px;
}

.QModalNewsletterContainer .QCardNewsletterInput {
  margin-bottom: 16px;
}

.QCardNewsletterCTA {
  display: flex;
  height: 40px;
  background: #1432FF;
  border-radius: 30px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  width: 100%;
  justify-content: space-between;
  padding: 0 24px;
}

.QCardNewsletterCTA:disabled {
  background: #1432ff;
  opacity: 0.5;
}

.QModalNewsletterContainer svg {
  fill: #FFFFFF
}

.QModalNewsletterContainer .QCardNewsletterLoadingOverlay {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.QModalNewsletter .QCardNewsletterSpinner {
  width: 129px;
  height: 129px;
  -webkit-animation: load3 15s infinite linear;
  animation: load3 1s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.QModalNewsletterContainer .QCardNewsletterSuccessMessage {
  margin: 0 16px;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}