.QCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--White);
  border-radius: 5px;
  width: 90%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.QCardHeader {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e5e7eb;
  align-self: flex-start;
  position: relative;
}

.QCardTitle {
  font-size: 14px;
  font-weight: 600;
  padding: 16px;
  color: #111827;
}

.QCardCloseButton {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 10px;
  height: 10px;
  color: #64748B;
}

.QCardResizeButton {
  position: absolute;
  right: 89px;
  top: 20px;
  width: 32px;
  height: 10px;
  color: #64748B;
  margin-right: 40px;
}

@media screen and (min-width: 768px) {
  .QCardContainer {
    width: 100%;
  }
}
