:root {
  --PrimaryColor: #16BDCA;
  --CheckboxLightBlue: #EDF8FF;
  --CheckboxDarkBlue: #BCE3EC;
  --CheckboxLightGray: #EAEBED;
  --ModalDarkBlue: #277BD9;
  --White: #ffffff;
  --FontGrayColor: #495058;
  --ButtonPrimaryDisabled: #8ad8df;
  --MainBackgroundColor: #F3F6F6;
  --FooterFontGray: #C1C7D0;
  --FooterFontLightGray: #FAF9F8;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', 'Times New Roman', Times, serif;
  /* TODO*/
  background: #F8FAFC;
}

strong {
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.QNegrito {
  font-weight: bold;
}

.QNegrito.i {
  font-style: italic;
}

/* total width */
.scrollbar::-webkit-scrollbar {
  background-color:rgb(0, 0, 0, 0);
  width:16px;
  height: 12px;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color:rgb(0, 0, 0, 0);
}
.scrollbar::-webkit-scrollbar-track:hover {
  background-color:#f4f4f4
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color:#babac0;
  border-radius:16px;
  border:2px solid rgb(0, 0, 0, 0);
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color:#a0a0a5;
  border:1px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {display:none}
