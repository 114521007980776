.QCertificateModalCard {
  width: 100vw;
  height: 100vh;
  z-index: 99;
  overflow: scroll;
}

.QCertificateModalContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.QCertificateModalLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  width: 100%;
  height: 100%;
}

.QCertificateModalRight {
  display: none;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;
}

.QCertificateStars {
  width: 72.89px;
  height: 68.13px;
  margin-bottom: 8px;
}

.QCertificateTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #051C33;
}

.QCertificateUser {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: blue
}

.QCertificateTitleDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #455A64;
  margin-top: 16px;
}

.QCertificateFirstStepButton {
  width: 256px;
  height: 36px;
  background: #1432FF;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 36px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 24px;
}

.QCertificateLadderImage {
  width: 632px;
  height: 641px;
  margin-top: 34px;
}

.QCertificateLadderMobileImage {
  width: 278px;
  height: 282px;
}

.QCertificateBackbuttonContainer {
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #051C33;
  margin: 8px 0 8px 0;
}

.QCertificateBackbutton {
  width: 24px;
  height: 24px;
  background: #EDF1F7;
  border-radius: 5px;
  margin: 5px 16px 0 0;
  align-self: start;
}

.QCertificateArrowIcon {
  color: #34475A;
}

.QCertificateSubTitle {
  width: 224px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  color: #051C33;
}

.QCertificateSubTitleDescription {
  width: 256px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #455A64;
  margin-bottom: 32px;
}

.QCertificateForm {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.QCertificateInputTitle {
  align-self: start;
  width: 256px;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #34475A;
  margin-bottom: 16px;
}

.QCertificateInput {
  width: 100%;
}

.QCertificateSubmitButton {
  align-self: start;
  width: 256px;
  height: 36px;
  background: #1432FF;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 36px;
  margin-top: 89px;
}

.QCertificateSubmitButton:disabled {
  background-color: #8594fca1;
}

.QCertificateLoadingContainer {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #051C33;
  margin-top: 38px;
}

.QCertificateLoadingText {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #051C33;
  width: 256px;
  margin-top: 51px;
}

.QCertificateThirdStepBackTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #051C33;
  margin: 40px 0 22px 0;
}

.QCertificateThirdStepContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
}

.QCertificateWarningImage {
  width: 128.71px;
  height: 129.76px;
  margin-bottom: 12px;
}

.QCertificateThirdStepText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #34475A;
  margin-bottom: 32px;
  width: 256px;
}

.QCertificateUserInfoBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 261px;
  height: 70px;
  background: rgba(20, 50, 255, 0.1);
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 32px;
}

.QCertificateUserName {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #212429;
  margin-bottom: 4px;
}

.QCertificateUserDocument {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #495058;
}

.QCertificateCTAContainer {
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
}

.QCertificateChangeInfo {
  width: 256px;
  height: 36px;
  background: #FFFFFF;
  border: 1px solid #1432FF;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 36px;
  color: #1432FF;
}

.QCertificateChangeInfo>.ripple {
  background-color: rgba(200, 200, 200, 0.2);
}

.QCertificateGenerateAction {
  width: 256px;
  height: 36px;
  background: #1432FF;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 36px;
  color: #FFFFFF;
}

.QCertificateLastStepContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  padding: 32px;
  width: 100%;
}

.QCertificateSuccessImage {
  width: 42px;
  height: 42px;
  margin-top: 34px;
  margin-bottom: 19px;
}

.QCertificateLastStepTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #051C33;
  margin-bottom: 8px;
}

.QCertificateLastStepDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #455A64;
  margin-bottom: 32px;
}

.QCertificateDownloadContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  background: #EDF1F7;
  border-radius: 20px;
  width: 256px;
  height: 68px;
  padding: 10px 16px;
  margin-bottom: 16px;
  cursor: pointer;
  border: 0;
}

.QCertificateDownloadTextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8px;
}

.QCertificateChevron {
  width: 15.93px;
  transform: rotate(270deg);
  position: absolute;
  right: 19.82px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.QCertificateDownloadType {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #34475A;
}

.QCertificateDownloadHint {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #34475A;
}

@media screen and (min-width: 1150px) {
  .QCertificateModalRight {
    display: flex;
  }

  .QCertificateModalLeft {
    padding-left: 97px;
    width: 50%;
  }

  .QCertificateStars {
    width: 171px;
    height: 160px;
    margin-top: 88px;
  }

  .QCertificateTitle {
    font-size: 32px;
  }

  .QCertificateUser {
    font-size: 32px;
  }

  .QCertificateTitleDescription {
    font-size: 24px;
    margin-top: 24px;
  }

  .QCertificateFirstStepButton {
    width: 433px;
    height: 48px;
    font-size: 16px;
    margin-bottom: 188px;
  }

  .QCertificateLadderMobileImage {
    display: none;
  }

  .QCertificateBackbuttonContainer {
    font-size: 24px;
    margin: 138px 0 12px 0;
    width: 100%;
  }

  .QCertificateBackbutton {
    width: 32px;
    height: 32px;
  }

  .QCertificateSubTitle {
    width: 492px;
  }

  .QCertificateSubTitleDescription {
    width: 492px;
    font-size: 16px;
    margin-bottom: 54px;
    align-self: start;
  }

  .QCertificateForm,
  .QCertificateInputTitle {
    width: 540px;
    font-size: 12px;
    margin-bottom: 24px;
    align-self: flex-start;
  }

  .QCertificateSubmitButton {
    align-self: flex-end;
    margin-top: 24px;
  }

  .QCertificateThirdStepBackTitle {
    align-self: flex-start;
    font-size: 24px;
    margin: 59px 0 32px 0;
  }

  .QCertificateWarningImage {
    width: 232px;
    height: 234px;
    margin-bottom: 32px;
  }

  .QCertificateThirdStepText {
    font-size: 24px;
    width: 648px;
  }

  .QCertificateUserInfoBox {
    width: 648px;
    height: 84px;
  }

  .QCertificateUserName {
    font-size: 16px;
    margin-bottom: 4px;
  }

  .QCertificateUserDocument {
    font-size: 16px;
  }

  .QCertificateCTAContainer {
    flex-direction: row;
  }

  .QCertificateGenerateAction {
    width: 278px;
  }

  .QCertificateSuccessImage {
    width: 85.75px;
    height: 85.75px;
    margin-top: 112px;
    margin-bottom: 22px;
  }

  .QCertificateLastStepTitle {
    font-size: 32px;
    margin-bottom: 16px;
  }

  .QCertificateLastStepDescription {
    font-size: 24px;
    margin-bottom: 48px;
  }

  .QCertificateDownloadContainer {
    width: 552px;
    margin-bottom: 8px;
  }
}
