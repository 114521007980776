.QResize {
  display: none;
}

.QResizeIconContainer:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #EDF1F7;
  border-radius: 50%;
}

.QResizeIcon {
  width: 16px;
  height: 16px;
}

@media screen and (min-width: 768px) {
  .QResize {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 10px;
    border-radius: 50%;
    cursor: pointer;
  }
}